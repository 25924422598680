<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    <el-form-item label="波比率" prop="baud">
      <el-input v-model="dataForm.baud" placeholder="波比率"></el-input>
    </el-form-item>
    <el-form-item label="轮询码" prop="enablereceivedcommand">
      <el-input v-model="dataForm.enablereceivedcommand" placeholder="轮询码"></el-input>
    </el-form-item>
    <el-form-item label="高级协议" prop="advancedId">
      <el-select v-model="dataForm.advancedId" >
          <el-option v-for="(item,index) in seniorData" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否公开" prop="isOpen">
      <el-switch v-model="dataForm.isOpen"></el-switch>
    </el-form-item>
    <el-form-item label="账号可见" prop="userList" v-if="!dataForm.isOpen">
      <el-select v-model="dataForm.userList" multiple filterable style="width:100%">
          <el-option v-for="(user,index) in userData" 
          :key="index" :label="user.username" :value="user.userId">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="品牌支持" prop="brandList">
      <el-checkbox-group v-model="dataForm.brandList">
        <el-checkbox v-for="(item,index) in brandData" :key="index" :label="item.id" name="brandList">{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          baud: '',
          enablereceivedcommand: '',
          advancedId: '',
          isOpen: false,
          brandList: []
        },
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          baud: [
            { required: true, message: '波比率不能为空', trigger: 'blur' }
          ],
          advancedId: [
            { required: true, message: '高级协议不能为空', trigger: 'blur' }
          ],
          isOpen: [
            { required: true, message: '是否公开不能为空', trigger: 'blur' }
          ]
        },
        seniorData: [],
        userData: [],
        brandData: []
      }
    },
    created() {
      this.getSeniorData()
      this.getUserData()
      this.getBrandData()
    },
    methods: {
      getSeniorData() { // 获取高级协议数据
        this.$http({
          url: this.$http.adornUrl(`/bus/busdata/list/senior`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.seniorData = data.list
          }
        })
      },
      getUserData() { // 获取用户数据
        this.$http({
          url: this.$http.adornUrl(`/sys/user/getAllUsers`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.userData = data.list
          }
        })
      },
      getBrandData() { // 获取用户数据
        this.$http({
          url: this.$http.adornUrl(`/bus/busbrand/getAllList`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.brandData = data.list
          }
        })
      },
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/bus/bussystem/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.busSystem.name
                this.dataForm.baud = data.busSystem.baud
                this.dataForm.enablereceivedcommand = data.busSystem.enablereceivedcommand
                this.dataForm.advancedId = data.busSystem.advancedId
                this.dataForm.isOpen = data.busSystem.isOpen === 1
                this.dataForm.userList = data.busSystem.userList
                this.dataForm.brandList = data.busSystem.brandList
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/bus/bussystem/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'baud': this.dataForm.baud,
                'enablereceivedcommand': this.dataForm.enablereceivedcommand,
                'advancedId': this.dataForm.advancedId,
                'isOpen': this.dataForm.isOpen ? 1 : 0,
                'userList': this.dataForm.userList,
                'brandList': this.dataForm.brandList
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      }
    }
  }
</script>
